<script lang="ts">
	import type { Image } from "../../../../core/schema/Image.js";
	import { thumb } from "../../../../core/utils/thumb.js";
	import { onMount } from "svelte";
	import { sortSourceSetQuery } from "../../utils/sortSourceSetQuery.js";
	import { parseSourceSet } from "../../utils/parseSourceSet.js";
	import type { SourceSetQuery } from "../../utils/SourceSetQuery.js";

	export let width: number;
	export let height: number;
	export let imgClass = "";
	export let loading: "eager" | "lazy";
	export let image: Image;
	export let alt: string | undefined;
	export let sourcesets: Partial<Record<SourceSetQuery, [number, number] | [number, number, Image]>> = {};
	export let bucketUrl: string;
	export let naturalHeight: number | undefined = undefined;
	export let naturalWidth: number | undefined = undefined;
	export let hasLightbox = false;

	$: sortedSourcesets = Object.entries(sourcesets).toSorted(([firstQuery], [secondQuery]) =>
		sortSourceSetQuery(firstQuery as SourceSetQuery, secondQuery as SourceSetQuery),
	) as [SourceSetQuery, [number, number] | [number, number, Image]][];

	async function refreshAndInitializeLightbox(): Promise<void> {
		if (!hasLightbox) {
			return;
		}

		// @ts-expect-error fslightbox does not provide types :)
		await import("../../../../core/assets/js/fslightbox-pro-3.4.1/fslightbox.js");
		window.refreshFsLightbox?.();
	}

	onMount(() => {
		void refreshAndInitializeLightbox();
	});

	function onImageChange(_image: Image): void {
		if (typeof window !== "undefined") {
			void refreshAndInitializeLightbox();
		}
	}
	$: onImageChange(image);
</script>

<picture>
	{#each sortedSourcesets as [query, [width, height, sourceSetImage]]}
		<source
			srcset={`${thumb(bucketUrl, width, height, sourceSetImage ?? image)} 1x`}
			type="image/webp"
			media={parseSourceSet(query)}
		/>
	{/each}

	<img
		class={imgClass}
		{alt}
		src={thumb(bucketUrl, width, 0, image)}
		{width}
		{height}
		{loading}
		bind:naturalHeight
		bind:naturalWidth
	/>
</picture>
