import type { Image } from "../Image.js";
import type { Product } from "../Product.js";
import type { ProductSize } from "../ProductSize.js";
import { productSizeKeyMap } from "../productSizeKeyMap.js";

export function getImagesForProduct(
	{ images, imagesPerSize }: Pick<Product, "images" | "imagesPerSize">,
	size: ProductSize,
): Image[] {
	const forSize = imagesPerSize?.[productSizeKeyMap[size]] ?? [];
	if (forSize.length > 0) {
		return forSize;
	}
	return images;
}
